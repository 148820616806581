import React from "react"
import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Package = ({
  title,
  subtitle,
  content,
  image,
  def,
  packageSlots,
  selectPackage,
  price,
}) => {
  const formatContent = content => {
    if (content.split("•")) {
      return content.split("•").map(
        (str, i) =>
          str.length > 1 && (
            <span key={i}>
              • {str} <br />
            </span>
          )
      )
    } else {
      return <p>{content}</p>
    }
  }
  const options = {
    renderNode: {
      [BLOCKS.LIST_ITEM]: node => {
        return (
          <span>
            • {node.content[0].content[0].value} <br />
          </span>
        )
      },
    },
  }

  return (
    <>
      {title && (
        <div
          className="horizontal-tile col-md-6 col-sm-10"
          style={{ display: "flex", maxHeight: "300px" }}
        >
          <div className="tile-left">
            <div className="background-image-holder">
              <img
                alt={title}
                className="background-image"
                src={image ? image : "img/seniorgroup.jpg"}
              />
            </div>
          </div>
          <div
            className="tile-right bg-secondary"
            style={{ overflow: "scroll" }}
          >
            <div className="description">
              <h4 className="mb8">{title}</h4>
              {subtitle && <h6 className="uppercase"> {subtitle} </h6>}

              {def ? (
                <p>{formatContent(content)}</p>
              ) : (
                documentToReactComponents(content, options)
              )}
              <a
                className="btn"
                role="button"
                onClick={() => selectPackage(title, price, packageSlots)}
                href="#form"
              >
                Select
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

Package.propTypes = {
  id: PropTypes.string,
  def: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.object,
  image: PropTypes.string,
}

export default Package
